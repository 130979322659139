import React, { useEffect, useState } from "react"
import PageLayout from "../../components/page-layout"
import SEO from "../../components/seo"
import Web3 from "web3"
import axios from "axios"
import styled from "styled-components"

import eggBusdContractAbi from "./contract-abis/egg-busd.json"
import eggBnbContractAbi from "./contract-abis/egg-bnb.json"
import gg1BusdContractAbi from "./contract-abis/gg1-busd.json"
import gg1BnbContractAbi from "./contract-abis/gg1-bnb.json"
import bnbBusdContractAbi from './contract-abis/bnb-busd.json'
import usdtBusdContractAbi from './contract-abis/usdt-busd.json'
import btcbBnbContractAbi from './contract-abis/btcb-bnb.json'
import ethBnbContractAbi from './contract-abis/eth-bnb.json'
import daiBusdContractAbi from './contract-abis/dai-busd.json'
import usdcBusdContractAbi from './contract-abis/usdc-busd.json'
import dotBnbContractAbi from './contract-abis/dot-bnb.json'
import cakeBusdContractAbi from './contract-abis/cake-busd.json'
import cakeBnbContractAbi from './contract-abis/cake-bnb.json'

const bscRemoteNode = "https://bsc-dataseed1.binance.org:443"

const goosePoolPairs = [
  'egg-busd',
  'egg-bnb',
  'gg1-busd',
  'gg1-bnb',
  'gg2-busd',
  'gg2-bnb',
  'bnb-busd',
  'usdt-busd',
  'btcb-bnb',
  'eth-bnb',
  'dai-busd',
  'usdc-busd',
  'dot-bnb',
  'cake-busd',
  'cake-bnb'
]

const contractAbis = {
  'egg-busd': eggBusdContractAbi,
  'egg-bnb': eggBnbContractAbi,
  'gg1-busd': gg1BusdContractAbi,
  'gg1-bnb': gg1BnbContractAbi,
  'gg2-busd': gg1BusdContractAbi,
  'gg2-bnb': gg1BnbContractAbi,
  'bnb-busd': bnbBusdContractAbi,
  'usdt-busd': usdtBusdContractAbi,
  'btcb-bnb': btcbBnbContractAbi,
  'eth-bnb': ethBnbContractAbi,
  'dai-busd': daiBusdContractAbi,
  'usdc-busd': usdcBusdContractAbi,
  'dot-bnb': dotBnbContractAbi,
  'cake-busd': cakeBusdContractAbi,
  'cake-bnb': cakeBnbContractAbi
}

const contractAddresses = {
  'egg-busd': '0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  'egg-bnb': '0xd1b59d11316e87c3a0a069e80f590ba35cd8d8d3',
  'gg1-busd': '0xb22708B6fe748EEc5bAFe770e4Ea48292C332aB0',
  'gg1-bnb': '0x3544bb241aAbeD125fe2eAd4DCC6271c546725f7',
  'gg2-busd': '0x78A4a02a0F437daf2ACDB09B1655A5c54747ABcB',
  'gg2-bnb': '0xC2015012fc30090b35Be493327Ed1f762e43f4C6',
  'bnb-busd': '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  'usdt-busd': '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd',
  'btcb-bnb': '0x7561eee90e24f3b348e1087a005f78b4c8453524',
  'eth-bnb': '0x70d8929d04b60af4fb9b58713ebcf18765ade422',
  'dai-busd': '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc',
  'usdc-busd': '0x680dd100e4b394bda26a59dd5c119a391e747d18',
  'dot-bnb': '0xbcd62661a6b1ded703585d3af7d7649ef4dcdb5c',
  'cake-busd': '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458',
  'cake-bnb': '0xa527a61703d82139f8a06bc30097cc9caa2df5a6'
}

// IMPORTANT, THE ORDER OF TOKENS MATTERS
// The order of tokens is used for matching reserves0 and reserves1
// Also: this is the name used on CG
const poolTokens = {
  'egg-busd': ['busd', 'goose-finance'],
  'egg-bnb': ['binancecoin', 'goose-finance'],
  'gg1-busd': ['GG1', 'busd'],
  'gg1-bnb': ['GG1', 'binancecoin'],
  'gg2-busd': ['GG2', 'busd'],
  'gg2-bnb': ['GG2', 'binancecoin'],
  'bnb-busd': ['binancecoin', 'busd'],
  'usdt-busd': ['tether', 'busd'],
  'btcb-bnb': ['bitcoin-bep2', 'binancecoin'],
  'eth-bnb': ['binance-eth', 'binancecoin'],
  'dai-busd': ['dai', 'busd'],
  'usdc-busd': ['usd-coin', 'busd'],
  'dot-bnb': ['polkadot', 'binancecoin'],
  'cake-busd': ['pancakeswap-token', 'busd'],
  'cake-bnb': ['pancakeswap-token', 'binancecoin']
}

const cgIdToName = {
  'busd': 'busd',
  'binancecoin': 'bnb',
  'tether': 'usdt',
  'polkadot': 'dot',
  'dai': 'dai',
  'goose-finance': 'egg',
  'bitcoin-bep2': 'btcb',
  'binance-eth': 'eth',
  'usd-coin': 'usdc',
  'pancakeswap-token': 'cake',
  'GG1': 'gg1',
  'GG2': 'gg2'
}

const initialClickedPairs = {
  'egg-busd': true,
  'egg-bnb': true
}

const LpTokenSelectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
  row-gap: 15px;
  padding-bottom: 1rem;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width:600px) and (max-width: 820px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const LpTokenSelectionCard = styled.div`
  padding: 0.75rem 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: ${props => props.isSelected ? "#ddd" : "white"};

  border: ${props => props.isSelected 
    ? "1px solid cornflowerblue" : "1px solid #ccc"};

  &:hover {
    cursor: pointer;
  }
`

const LpTokenValueCardContainer = styled.div``

const LpTokenValueCard = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
`

const LpTokenValueCardTitle = styled.div`
  font-weight: bold;
`

const CalcInput = styled.input`
  width: 80px;
  margin-left: 0.5rem;
  padding: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 3px;
`

const CalcResult = styled.div`
  padding-top: 0.5rem;
`

const formatNumber = (num, decimalPlaces) => {
  return numberWithCommas(num.toFixed(decimalPlaces))
}

const getCoinPrices = ids => {
  const url = `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd`
  return axios.get(url)
}

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const LpTokenSelection = ({lpTokenPair, isSelected, onClick}) => {
  const pairName = lpTokenPair.toUpperCase()
  return (
    <LpTokenSelectionCard 
      onClick={() => onClick(lpTokenPair)}
      isSelected={isSelected}
    >
      {pairName}
    </LpTokenSelectionCard>
  )
}

const LpTokenValue = ({pair}) => {
  const pairName = pair.toUpperCase()
  const [lpInfo, setLpInfo] = useState({})
  const [providedQuantity, setProvidedQuantity] = useState(0)

  const collectPrice = (web3, apiRes, tokenId, cb) => {
    if (tokenId !== 'GG1' && tokenId !== 'GG2') {
      cb(apiRes.data[tokenId].usd)
      return
    }
    if (tokenId === 'GG1') {
      const gg1BusdContract = new web3.eth.Contract(contractAbis['gg1-busd'], contractAddresses['gg1-busd'])
      gg1BusdContract.methods.getReserves().call().then(res => {
        cb(res[1] / res[0])
      })
    }
    if (tokenId === 'GG2') {
      const gg2BusdContract = new web3.eth.Contract(contractAbis['gg2-busd'], contractAddresses['gg2-busd'])
      gg2BusdContract.methods.getReserves().call().then(res => {
        cb(res[1] / res[0])
      })
    }
  }

  useEffect(() => {
    const web3 = new Web3(bscRemoteNode)
    const contractAbi = contractAbis[pair]
    const contractAddress = contractAddresses[pair]
    const contract = new web3.eth.Contract(contractAbi, contractAddress)

    const tokenIds = poolTokens[pair]
    getCoinPrices(tokenIds).then(apiRes => {
      contract.methods.totalSupply().call().then(supply => {
        contract.methods.getReserves().call().then(res => {
          collectPrice(web3, apiRes, tokenIds[0], priceTokenA => {
            collectPrice(web3, apiRes, tokenIds[1], priceTokenB => {
              setLpInfo({
                supply: supply / Math.pow(10, 18),
                reserveA: res[0] / Math.pow(10, 18),
                reserveB: res[1] / Math.pow(10, 18),
                priceTokenA,
                priceTokenB
              })
            })
          })
        })
      })
    })
  }, [pair])

  const priceAName = cgIdToName[poolTokens[pair][0]].toUpperCase()
  const priceBName = cgIdToName[poolTokens[pair][1]].toUpperCase()

  const lpSupply = lpInfo["supply"] ? lpInfo["supply"] : 0
  const reserveA = lpInfo["reserveA"] ? lpInfo["reserveA"] : 0
  const reserveB = lpInfo["reserveB"] ? lpInfo["reserveB"] : 0
  const priceA = lpInfo["priceTokenA"] ? lpInfo["priceTokenA"] : 0
  const priceB = lpInfo["priceTokenB"] ? lpInfo["priceTokenB"] : 0

  const totalPoolValue = (reserveA * priceA) + (reserveB * priceB)
  const pricePerLpCoin = lpSupply > 0 ? totalPoolValue / lpSupply : 0

  return (
    <LpTokenValueCard>
      <LpTokenValueCardTitle>
        {pairName}
      </LpTokenValueCardTitle>
      <p>{priceAName}:&nbsp;${formatNumber(priceA, 2)}</p>
      <p>{priceBName}:&nbsp;${formatNumber(priceB, 2)}</p>
      <p>{pairName} LP Token Supply: {formatNumber(lpSupply, 2)}</p>
      <p>Value of Entire {pairName} Pool: ${formatNumber(totalPoolValue, 2)}</p>
      <p>Price Per {pairName} LP Token: ${formatNumber(pricePerLpCoin, 2)}</p>
      <hr />
      <p>Calculate Your LP Token Value</p>
      <div>
        <div>
          <span>LP Token Quantity</span>
          <CalcInput 
            value={providedQuantity}
            type="number" 
            onChange={e => setProvidedQuantity(e.target.value)}
          />
        </div>
        <CalcResult>
          { 
            (pricePerLpCoin > 0 && providedQuantity !== 0)
            ? `= $${formatNumber(pricePerLpCoin * providedQuantity, 2)}`
            : "= $0.00"
          }
        </CalcResult>
      </div>
    </LpTokenValueCard>
  )
}

export default function CalcEggLpValue() {

  const [clickedPairs, setClickedPairs] = useState(initialClickedPairs)

  const onSelectionClick = lpTokenPair => {
    if (lpTokenPair in clickedPairs) {
      clickedPairs[lpTokenPair] = !clickedPairs[lpTokenPair]
      setClickedPairs(JSON.parse(JSON.stringify(clickedPairs)))
    } else {
      clickedPairs[lpTokenPair] = true;
      setClickedPairs(JSON.parse(JSON.stringify(clickedPairs)))
    }
  }

  const noneSelected = Object.keys(clickedPairs).reduce((acc, cur) => {
    return clickedPairs[cur] ? acc.concat(cur) : acc
  }, []).length === 0

  return (
    <PageLayout showAffiliateLink>
      <SEO
        title="Calculate Goose LP Token Values"
        description="Calculate LP Token Values used on Goose Finance - EGG-BUSD, EGG-BNB, and others"
      />

      <h3>Select LP Tokens</h3>
      <LpTokenSelectionContainer>
        {
          goosePoolPairs.map((pair, i) => {
            return (
              <LpTokenSelection 
                key={i}
                lpTokenPair={pair}
                onClick={onSelectionClick}
                isSelected={clickedPairs[pair]}
              />
            )
          })
        }
      </LpTokenSelectionContainer>
      <hr/>

      <h3>LP Token Valuations</h3>
      <LpTokenValueCardContainer>
        {
          Object.keys(clickedPairs).map((key, i) => {
            if (!clickedPairs[key]) {
              return null;
            }
            return (
              <LpTokenValue
                key={i} 
                pair={key} 
              />
            )
          })
        }
        { 
          noneSelected &&
          <p>Please select at least one LP token.</p>
        }
      </LpTokenValueCardContainer>
    </PageLayout>
  )
}
